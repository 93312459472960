












































































































































































































































































































































































































































































































































import axios from 'axios';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { baConfig } from '@/../config';
import {
  DefaultPricingRule,
  DefaultPricingRuleInput,
  Org,
  PricingMethodology,
  PricingRule,
  PricingRuleAction,
  PricingRuleActionSource,
  PricingRuleActionSourceExchange,
  PricingRuleActionSourceType,
  PricingRuleActionType,
  PricingRuleApplication,
  PricingRuleInput,
  PricingRuleResolution,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDatePicker2 from '@/components/ui/UiDatePicker2.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import { MUT_SNACKBAR } from '@/store';
import { getEndpointUrl } from '@/utils/endpointUrlUtil';
import { assertDefined } from '@/utils/guards';

import { RateTable } from './types/rateTableTypes';

type CreatePricingRuleActionSource = PricingRuleActionSource & {
  coin: string;
};
type CreatePricingRuleAction = PricingRuleAction & {
  sourceOrdering: (PricingRuleActionSource & { coin: string; conversionPricingDirective: any })[];
};

type CreateParams = {
  methodology: PricingMethodology;
  coinType: string;
  action: CreatePricingRuleAction;
  resolution: PricingRuleResolution;
};
type CreatePricingRule = Partial<PricingRule> & CreateParams;

@Component({
  apollo: {
    pricingRules: {
      query: gql`
        query getPricingRules($orgId: ID!) {
          pricingRules(orgId: $orgId) {
            id
            assetId
            ticker
            priority
            application
            action {
              type
              price
              sourceOrdering {
                type
                exchange
                fiatToCoinTickerMapping
                conversionAssetTicker
                conversionPricingDirective {
                  type
                  price
                  source {
                    type
                    exchange
                    fiatToCoinTickerMapping
                  }
                }
                rateTableId
                sourceLocatorId
                sourceLocator
              }
              overrideTicker
              percentage
            }
            fromSEC
            toSEC
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
    defaultPricingRule: {
      query: gql`
        query getDefaultPricingRule($orgId: ID!) {
          defaultPricingRule(orgId: $orgId) {
            methodology
            resolution
            action {
              type
              price
              sourceOrdering {
                type
                exchange
                fiatToCoinTickerMapping
                rateTableId
                sourceLocatorId
                sourceLocator
              }
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  components: {
    UiButton,
    UiRadioGroup,
    UiTextEdit,
    UiSelect,
    UiDatePicker,
    UiDataTable,
    UiLoading,
    UiSelect2,
    UiDatePicker2,
  },
})
export default class PricingRules extends BaseVue {
  public priceTrhuAsset = '';
  public isCreatingRule = false;

  public formValidated = false;

  public headers = [
    {
      id: 'coin',
      label: 'Coin',
      defaultVisibility: true,
      selector: (item: PricingRule) => (item.ticker ? item.ticker : 'Default'),
    },
    {
      id: 'fromSEC',
      label: 'Start Date',
      defaultVisibility: true,
      selector: (item: PricingRule) =>
        item.fromSEC ? moment.unix(item.fromSEC).tz(this.preferredTimezone).format('YYYY-MM-DD') : '',
    },
    {
      id: 'toSEC',
      label: 'End Date',
      defaultVisibility: true,
      selector: (item: PricingRule) =>
        item.toSEC && Number(item.toSEC) !== Number.MAX_SAFE_INTEGER
          ? moment.unix(item.toSEC).tz(this.preferredTimezone).format('YYYY-MM-DD')
          : '',
    },
  ];

  public priceThroughAssetPricingSources = [
    { label: 'Binance', id: 'Binance' },
    { label: 'Coinbase', id: 'Coinbase' },
    { label: 'Coin Gecko', id: 'CoinGecko' },
    { label: 'Crypto Compare', id: 'CryptoCompare' },
  ];

  public pricingSources = [...this.priceThroughAssetPricingSources, { label: 'Coin Market Cap', id: 'CoinMarketCap' }];

  public pricingDirectivePricingSources = [
    { label: 'Binance', id: 'binance' },
    { label: 'Coinbase', id: 'coinbase' },
    { label: 'Coin Gecko', id: 'coin-gecko' },
    { label: 'Crypto Compare', id: 'crypto-compare' },
    { label: 'Fixer', id: 'Fixer' },
  ];

  public blankRule: CreatePricingRule = {
    coinType: 'Default',
    assetId: '',
    ticker: '',
    priority: 1,
    application: PricingRuleApplication.BeforeDefault,
    resolution: PricingRuleResolution.Hour,
    methodology: PricingMethodology.Close,
    action: {
      type: PricingRuleActionType.PriceOverride,
      price: undefined,
      sourceOrdering: Array.from([
        {
          coin: '',
          type: PricingRuleActionSourceType.Binance,
          exchange: PricingRuleActionSourceExchange.None,
          fiatToCoinTickerMapping: {},
          conversionPricingDirective: {
            source: { type: undefined },
            type: undefined,
          },
          rateTableId: undefined,
        },
      ]),
      overrideTicker: null,
      percentage: null,
    },
    fromSEC: '',
    toSEC: '',
  };

  public currentRule: CreatePricingRule = this.blankRule;

  public pricingRules: PricingRule[] = [];
  public defaultPricingRule: DefaultPricingRule | null = null;
  public isLoading = 0;
  public isSaving = false;
  public coinMappingExchanges: string[] = [
    'Binance',
    'BinanceUS',
    'HuobiGlobal',
    'Poloniex',
    'Kucoin',
    'Bitvavo',
    'Bit.com',
    'MEXC',
  ];

  public rateTables: RateTable[] = [];
  public isLoadingRateTables = false;

  public getPricingDirectivePricingSource(index: number) {
    return this.isEditing
      ? this.pricingDirectivePricingSources.find((pricingDirectivePricingSource) => {
          if (
            (this.currentRule as CreatePricingRule).action.sourceOrdering[index].conversionPricingDirective.source !==
            undefined
          ) {
            return (
              pricingDirectivePricingSource.id ===
              this.mapSource(
                (this.currentRule as CreatePricingRule).action.sourceOrdering[index].conversionPricingDirective.source
                  .type
              )
            );
          } else {
            return undefined;
          }
        })
      : (this.currentRule as CreatePricingRule).action.sourceOrdering[index].conversionPricingDirective.source.type;
  }

  public onCreateRule() {
    this.isCreatingRule = true;
    this.currentRule = this.blankRule;
  }

  public handlePricingDirectivePricingSourceSwitch(value: any, i: number) {
    (this.currentRule as CreatePricingRule).action.sourceOrdering[i].conversionPricingDirective.source = {
      type: value,
    };
  }

  public onAddNewSource() {
    (this.currentRule as CreatePricingRule).action.sourceOrdering.push({
      type: PricingRuleActionSourceType.Binance,
      exchange: PricingRuleActionSourceExchange.Binance,
      coin: '',
      fiatToCoinTickerMapping: {},
      conversionPricingDirective: { type: undefined, price: undefined, source: { type: undefined } },
      rateTableId: undefined,
    });
  }

  public get isFormValid() {
    if (this.currentRule.coinType === 'Coin' && !this.currentRule.ticker) {
      console.error('fail ticker');
      return false;
    }
    if (this.currentRule.coinType === 'Coin' && (!this.currentRule.priority || this.currentRule.priority < 0)) {
      console.error('fail priority');

      return false;
    }
    if (this.currentRule.coinType === 'Coin' && !this.currentRule.application) {
      console.error('fail application');
      return false;
    }
    if (this.currentRule.coinType === 'Coin' && !this.currentRule.action.type) return false;
    if (this.currentRule.coinType === 'Default' && !this.currentRule.resolution) return false;
    if (!this.currentRule.action.type) return false;
    if (
      this.currentRule.action.type === 'PriceOverride' &&
      (!this.currentRule.action.price || this.currentRule.action.price < 0)
    )
      return false;
    if (this.currentRule.action.type === 'OrderedSourceSearch') {
      for (const order of this.currentRule.action.sourceOrdering) {
        if (!order.type) return false;
        if (order.type === 'CryptoCompare') {
          //  we can remove the following check once we implement minute resolution
          const currentlyUnsupportedResolutions = ['Minute', 'Minute5', 'Minute10', 'Minute15'];
          if (currentlyUnsupportedResolutions.includes(this.currentRule.resolution)) {
            return false;
          }
          if (!order.exchange) return false;
          if (this.coinMappingExchanges.includes(order.exchange) && !order.coin) return false;
        }
        if (order.type === 'Binance') {
          if (!order.coin) {
            console.log('fail binance');
            return false;
          }
        }
      }
    }

    if (
      this.currentRule.action.type === PricingRuleActionType.RateTableLookup &&
      this.currentRule.action.sourceOrdering.some((s) => !s?.rateTableId)
    ) {
      return false;
    }

    if (this.currentRule.coinType === 'Coin') {
      if (!this.currentRule.fromSEC) {
        console.log('fail sec');

        return false;
      }
      if (this.currentRule.toSEC && this.currentRule.fromSEC > this.currentRule.toSEC) {
        console.log('fail sec');
        return false;
      }
    }

    return true;
  }

  public mapSource(source: string): any {
    switch (source) {
      case 'CoinGecko':
        return 'coin-gecko';
      case 'Coinbase':
        return 'coinbase';
      case 'Binance':
        return 'binance';
      case 'CryptoCompare':
        return 'crypto-compare';
      default:
        return source;
    }
  }

  public get priorityValid() {
    return !!this.currentRule.priority && this.currentRule.priority > 0 && this.currentRule.priority < 21;
  }

  /**
   *
   * @param action
   */
  public getPriceOverrideAction(action: any): PricingRuleAction {
    const newAction: PricingRuleAction = { type: action.type };
    newAction.price = Number(action.price);
    return newAction;
  }

  public getRateTableLookupAction(org: Org, action: any): PricingRuleAction {
    const newAction: PricingRuleAction = { type: action.type };
    newAction.sourceOrdering = [
      {
        type: PricingRuleActionSourceType.RateTable,
        rateTableId: action.sourceOrdering[0].rateTableId,
        sourceLocator: 'org', // Hard coded as it is the only option for now, might change
        sourceLocatorId: org.id,
      },
    ];
    return newAction;
  }

  /**
   *
   * @param org
   * @param action
   */
  public getOrderedSourceSearchAction(org: Org, action: any): PricingRuleAction {
    const newAction: PricingRuleAction = { type: action.type };
    const baseCurrency = org.baseCurrency;
    newAction.sourceOrdering = action.sourceOrdering.map((order: CreatePricingRuleActionSource) => {
      switch (order.type) {
        case 'Binance':
          if (!baseCurrency) {
            throw new Error(
              'Unable to create new ordered source search action for Binance. Org base currency null or undefined'
            );
          }
          return {
            type: order.type,
            fiatToCoinTickerMapping: {
              [baseCurrency]: order.coin,
            },
          };
        case 'CryptoCompare':
          if (!baseCurrency) {
            throw new Error(
              'Unable to create new ordered source search action for CryptoCompare. Org base currency null or undefined'
            );
          }
          return {
            type: order.type,
            exchange: order.exchange,
            fiatToCoinTickerMapping: {
              [baseCurrency]: order.coin,
            },
          };
        default:
          return {
            type: order.type,
          };
      }
    });
    newAction.overrideTicker = action.overrideTicker;
    newAction.percentage = action.percentage;
    return newAction;
  }

  public getPriceThroughAssetAction(org: Org, action: any): PricingRuleAction {
    const newAction: PricingRuleAction = { type: action.type };
    const baseCurrency = org.baseCurrency;
    newAction.sourceOrdering = action.sourceOrdering.map((source: CreatePricingRuleActionSource) => {
      let returnVal: PricingRuleActionSource;

      if (source.conversionPricingDirective.type === 'pricing-source') {
        returnVal = {
          type: source.type,
          conversionAssetTicker: source.conversionAssetTicker,
          conversionPricingDirective: {
            type: source.conversionPricingDirective.type,
          },
        };
        returnVal.conversionPricingDirective.source = { type: source.conversionPricingDirective.source.type };
        switch (source.conversionPricingDirective.source.type) {
          case 'binance':
            if (!baseCurrency) {
              throw new Error(
                'Unable to create new ordered source search action for Binance. Org base currency null or undefined'
              );
            }
            returnVal.conversionPricingDirective.source.fiatToCoinTickerMapping = {
              [baseCurrency]: source.conversionPricingDirective.source.coin,
            };
            break;
          case 'crypto-compare':
            if (!baseCurrency) {
              throw new Error(
                'Unable to create new ordered source search action for Binance. Org base currency null or undefined'
              );
            }
            returnVal.conversionPricingDirective.source.exchange = this.mapSource(
              source.conversionPricingDirective.source.exchange
            );
            returnVal.conversionPricingDirective.source.fiatToCoinTickerMapping = {
              [baseCurrency]: source.conversionPricingDirective.source.coin,
            };
            break;
        }
      } else if (source.conversionPricingDirective.type === 'static') {
        returnVal = {
          type: source.type,
          conversionAssetTicker: source.conversionAssetTicker,
          conversionPricingDirective: {
            type: source.conversionPricingDirective.type,
            price: source.conversionPricingDirective.price,
          },
        };
      } else {
        throw new Error('Unable to get priceThroughAssetAction. Unsupported conversionPricingDirectiveType');
      }

      switch (source.type) {
        case 'Binance':
          if (!baseCurrency) {
            throw new Error(
              'Unable to create new ordered source search action for Binance. Org base currency null or undefined'
            );
          }
          returnVal.fiatToCoinTickerMapping = {
            [baseCurrency]: source.coin,
          };
          return returnVal;

        case 'CryptoCompare':
          if (!baseCurrency) {
            throw new Error(
              'Unable to create new ordered source search action for CryptoCompare. Org base currency null or undefined'
            );
          }
          returnVal.exchange = source.exchange;
          returnVal.fiatToCoinTickerMapping = {
            [baseCurrency]: source.coin,
          };
          return returnVal;
        default:
          return returnVal;
      }
    });
    newAction.overrideTicker = action.overrideTicker;
    newAction.percentage = action.percentage;
    return newAction;
  }

  public getAction(action: CreatePricingRuleAction, org: Org) {
    switch (action.type) {
      case PricingRuleActionType.PriceOverride:
        return this.getPriceOverrideAction(action);
      case PricingRuleActionType.OrderedSourceSearch:
        return this.getOrderedSourceSearchAction(org, action);
      case PricingRuleActionType.PriceThroughAsset:
        return this.getPriceThroughAssetAction(org, action);
      case PricingRuleActionType.RateTableLookup:
        return this.getRateTableLookupAction(org, action);
      default:
        throw new Error('Unsupported action type');
    }
  }

  public get preferredTimezone() {
    return this.$store.state.currentOrg.timezone;
  }

  /**
   *
   */
  public async onAddNewRule() {
    this.formValidated = true;
    if (!this.isFormValid) {
      this.$store.commit(MUT_SNACKBAR, {
        color: 'warning',
        message: 'Form invalid, Please check the highlighted fields and try again.',
      });
      return;
    }
    try {
      let assetId: string;
      let newRule: PricingRuleInput | DefaultPricingRuleInput;
      let resp: any;
      let isSuccess = false;
      const { coinType, ticker, priority, resolution, fromSEC, toSEC, action, methodology, ...otherAttrs } =
        this.currentRule;
      const org: Org = this.$store.state.currentOrg;
      const orgId = org.id;

      // validate form

      // set saving status
      this.isSaving = true;
      const newAction: PricingRuleAction = this.getAction(action, org);

      if (coinType === 'Coin') {
        if (ticker !== '*') {
          const assetsApiUrl = `${baConfig.addressSvcUrl}/symbols/${ticker}`;
          const { data } = await axios.get(assetsApiUrl);
          assetId = `COIN.${data.coinId}`;
        } else {
          assetId = '*';
        }
        newRule = {
          ...otherAttrs,
          assetId,
          priority: Number(priority),
          action: newAction,
        } as PricingRuleInput;

        newRule.fromSEC = moment.tz(fromSEC, this.preferredTimezone).unix();
        newRule.toSEC = toSEC ? moment.tz(toSEC, this.preferredTimezone).unix() : undefined;
      } else {
        newRule = {
          resolution,
          action: newAction,
          methodology,
        } as DefaultPricingRuleInput;
      }
      if (coinType === 'Coin') {
        if ((newRule as PricingRuleInput).id) {
          const resp: any = await this.$apollo
            .mutate({
              mutation: gql`
                mutation UpdatePricingRule($orgId: ID!, $rule: PricingRuleInput!) {
                  updatePricingRule(orgId: $orgId, rule: $rule) {
                    success
                    errors
                  }
                }
              `,
              variables: {
                orgId,
                rule: newRule,
              },
            })
            .catch((error) => {
              return { error: error.message };
            });
          isSuccess = resp.data.updatePricingRule.success;
        } else {
          resp = await this.$apollo
            .mutate({
              mutation: gql`
                mutation CreatePricingRule($orgId: ID!, $rule: PricingRuleInput!) {
                  createPricingRule(orgId: $orgId, rule: $rule) {
                    success
                    errors
                  }
                }
              `,
              variables: {
                orgId,
                rule: newRule,
              },
            })
            .catch((error) => {
              return { error: error.message };
            });
          isSuccess = resp.data.createPricingRule.success;
        }
      } else {
        resp = await this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateDefaultPricingRule($orgId: ID!, $rule: DefaultPricingRuleInput!) {
                createDefaultPricingRule(orgId: $orgId, rule: $rule) {
                  success
                  errors
                }
              }
            `,
            variables: {
              orgId,
              rule: newRule,
            },
          })
          .catch((error) => {
            return { error: error.message };
          });
        isSuccess = resp.data.createDefaultPricingRule.success;
      }
      if (isSuccess) {
        this.showSnackbar('success', (this.$t('_successRule') as string).replace('[ACTION]', 'Created'));
        if (coinType === 'Coin' && !(newRule as PricingRuleInput).id) {
          const updateResult = await Promise.all(
            this.pricingRules
              .filter(
                (rule) =>
                  rule.ticker === ticker &&
                  Number(rule.toSEC) > (newRule as PricingRuleInput).fromSEC &&
                  rule.fromSEC < (newRule as PricingRuleInput).fromSEC
              )
              .map(async (_rule: any) => {
                const { __typename, ticker, ...rule } = _rule;
                delete rule.action.__typename;
                if (rule.action.sourceOrdering) {
                  for (let i = 0; i < rule.action.sourceOrdering.length; i++) {
                    delete rule.action.sourceOrdering[i].__typename;
                  }
                } else {
                  delete rule.action.sourceOrdering;
                }
                if (!rule.action.price) {
                  delete rule.action.price;
                }
                _rule.toSEC = rule.toSEC = (newRule as PricingRuleInput).fromSEC - 1;
                const resp: any = await this.$apollo
                  .mutate({
                    mutation: gql`
                      mutation UpdatePricingRule($orgId: ID!, $rule: PricingRuleInput!) {
                        updatePricingRule(orgId: $orgId, rule: $rule) {
                          success
                          errors
                        }
                      }
                    `,
                    variables: {
                      orgId,
                      rule,
                    },
                  })
                  .catch((error) => {
                    return { error: error.message };
                  });
                return resp.data.updatePricingRule.success;
              })
          );
        }
      } else {
        this.showErrorSnackbar('Problem creating rule: ' + resp.data.createPricingRule.errors.join('<br />'));
        return;
      }
      if (coinType === 'Coin') {
        if (!(newRule as PricingRuleInput).id) {
          this.pricingRules.push(this.cloneDeep({ ...newRule, ticker }));
        } else {
          this.pricingRules = this.pricingRules.map((rule) => {
            if (rule.id !== (newRule as PricingRuleInput).id) return rule;
            return this.cloneDeep({ ...newRule, ticker });
          });
        }
      } else {
        this.defaultPricingRule = this.cloneDeep(newRule);
      }
      this.isCreatingRule = false;
    } catch (e: any) {
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: 'Problem add new pricing rule: ' + e.message,
      });
    } finally {
      this.isSaving = false;
    }
  }

  public get rules() {
    if (!this.defaultPricingRule) return this.pricingRules;
    return [this.defaultPricingRule, ...this.pricingRules];
  }

  public get isEditing() {
    return !!this.currentRule.id;
  }

  showSnackbar(action: string, message: string) {
    this.$store.commit(MUT_SNACKBAR, {
      color: action,
      message,
    });
  }

  openEditPage(_rule: PricingRule | DefaultPricingRule) {
    console.log(_rule);
    const action: any = {
      type: _rule.action.type,
    };
    const org = this.$store.state.currentOrg;
    const baseCurrency = org.baseCurrency;

    if (action.type === 'PriceOverride') {
      action.price = String(_rule.action.price);
      action.sourceOrdering = [
        {
          type: null,
          exchange: 'None',
          fiatToCoinTickerMapping: {},
        },
      ];
    } else if (action.type === 'PriceThroughAsset') {
      action.price = undefined;
      action.sourceOrdering = _rule.action.sourceOrdering?.map((order) => {
        assertDefined(order);
        console.log(order.conversionPricingDirective);
        return {
          type: order.type,
          exchange: order.exchange ?? 'None',
          fiatToCoinTickerMapping: order.fiatToCoinTickerMapping,
          conversionAssetTicker: order.conversionAssetTicker ?? undefined,
          conversionPricingDirective: {
            ...order.conversionPricingDirective,
            source: {
              ...order.conversionPricingDirective.source,
              coin: order.conversionPricingDirective.source.fiatToCoinTickerMapping
                ? order.conversionPricingDirective.source.fiatToCoinTickerMapping[baseCurrency]
                : undefined,
            },
          },
          coin:
            order.fiatToCoinTickerMapping && Object.values(order.fiatToCoinTickerMapping).length
              ? Object.values(order.fiatToCoinTickerMapping)[0]
              : '',
        };
      });
      action.overrideTicker = _rule.action.overrideTicker;
      action.percentage = _rule.action.percentage;
    } else if (action.type === 'OrderedSourceSearch') {
      action.price = undefined;
      action.sourceOrdering = _rule.action.sourceOrdering?.map((order) => {
        assertDefined(order);
        return {
          type: order.type,
          exchange: order.exchange ?? 'None',
          fiatToCoinTickerMapping: order.fiatToCoinTickerMapping,
          coin:
            order.fiatToCoinTickerMapping && Object.values(order.fiatToCoinTickerMapping).length
              ? Object.values(order.fiatToCoinTickerMapping)[0]
              : '',
        };
      });
      action.overrideTicker = _rule.action.overrideTicker;
      action.percentage = _rule.action.percentage;
    } else if (action.type === PricingRuleActionType.RateTableLookup) {
      this.onActionTypeChange(PricingRuleActionType.RateTableLookup);

      action.price = undefined;
      action.sourceOrdering = _rule.action.sourceOrdering?.map((order) => {
        assertDefined(order);
        return {
          type: order.type,
          exchange: 'None',
          rateTableId: order.rateTableId,
          sourceLocator: order.sourceLocator,
          sourceLocatorId: order.sourceLocatorId,
          fiatToCoinTickerMapping: {},
          conversionPricingDirective: {},
        };
      });
    } else {
      action.price = undefined;
      action.sourceOrdering = [
        {
          type: null,
          exchange: 'None',
          fiatToCoinTickerMapping: {},
          conversionPricingDirective: {},
        },
      ];
    }

    if ((_rule as any).ticker) {
      const rule = _rule as PricingRule;
      this.currentRule = {
        id: rule.id,
        coinType: 'Coin',
        assetId: rule.assetId,
        ticker: rule.ticker,
        priority: rule.priority,
        application: rule.application,
        action: action,
        fromSEC: moment.unix(rule.fromSEC).tz(this.preferredTimezone).format('YYYY-MM-DD'),
        toSEC:
          rule.toSEC && Number(rule.toSEC) !== Number.MAX_SAFE_INTEGER
            ? moment.unix(rule.toSEC).tz(this.preferredTimezone).format('YYYY-MM-DD')
            : '',
      } as CreatePricingRule;
    } else {
      const rule = _rule as DefaultPricingRule;
      this.currentRule = {
        coinType: 'Default',
        resolution: rule.resolution,
        action: action,
        methodology: rule.methodology ?? PricingMethodology.Close,
      } as CreatePricingRule;
    }
    this.isCreatingRule = true;
  }

  onCoinTypeChanged(type: 'Default' | 'Coin') {
    this.formValidated = false;
    this.currentRule = { ...this.blankRule, coinType: type };
  }

  onActionTypeChange(actionType: string) {
    if (actionType === PricingRuleActionType.RateTableLookup && !this.rateTables?.length) {
      this.loadRateTables();
    }
  }

  async loadRateTables() {
    this.isLoadingRateTables = true;

    try {
      const svcURL = baConfig.api2Url || '';
      const orgId = this.$store.state.currentOrg.id;
      const endpointURL = getEndpointUrl(svcURL, ['v2', 'orgs', orgId, 'rate-tables']);
      const resp = await axios.get<{ rateTables: RateTable[] }>(endpointURL, {
        withCredentials: true,
      });

      this.isLoadingRateTables = false;

      if (resp.status === 200) {
        this.rateTables = resp.data?.rateTables ?? [];
      } else {
        // TODO: handle table name exists errors or validate beforehand
        this.showErrorSnackbar('Could not fetch rate tables');
      }
    } catch (error) {
      console.error(error);
      this.isLoadingRateTables = false;
      this.showErrorSnackbar('Unexpected error while loading rate tables');
    }
  }

  @Watch('$store.state.currentOrg.id')
  onOrgChange() {
    if (this.rateTables) {
      this.rateTables = [];
      this.loadRateTables();
    }
  }
}
